@import "../variables/variables.scss";

.introduction {
  background-color: $color-global;
  color: $color-text-light;
  padding: 10vh 10vw 5vh;
  .top-content {
    max-width: 40vw;
    margin-bottom: 15vh;
    @media (max-width: 1280px) {
      max-width: 100vw;
    }
    h2 {
      margin-top: 0;
      font-family: $font-urbanist;
    }
    p {
      font-family: $font-urbanist;
      margin: 24px 0;
      line-height: 1.2;
    }
  }

  .bottom-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // justify-content: space-between;
    align-items: center;

    /* .ornaments {
      font-family: $font-erotique-ornaments;
    } */

    .text {
      font-family: $font-urbanist;
      max-width: 30vw;
      @media (max-width: 768px) {
      max-width: 100vw;
    }
    }
  }
}
